import styled from 'styled-components'
import { Color, Spacing } from 'atomic/legacy/obj.constants'
import { highlightStyle } from 'atomic/legacy/obj.mixin'
import { InputValue } from 'atomic/legacy/atm.typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const checkboxHeight: number = 24
export const marginBetweenCheckboxItems = Spacing.XSmall

export const CheckboxFieldStyled = styled.div`
  position: relative;
  cursor: pointer;

  & ~ * {
    margin-top: ${marginBetweenCheckboxItems};
  }
`

export const CheckboxFieldTextStyled = styled(InputValue)`
  padding-left: ${Spacing.XLarge};
  line-height: ${checkboxHeight}px;
  z-index: 2;
  ${highlightStyle};
`

export const CheckboxCheckedStyled = styled(FontAwesomeIcon).attrs({ icon: 'check-square' })`
  position: absolute;
  font-size: ${checkboxHeight}px !important;
  color: ${props => props.theme.color.primary};
  z-index: 1;
  opacity: 0;
`

export const CheckboxUncheckedStyled = styled(FontAwesomeIcon).attrs({ icon: ['far', 'square'] })`
  position: absolute;
  font-size: ${checkboxHeight}px !important;
  color: ${Color.GrayDark};
  z-index: 0;
  opacity: 1;
`

export const CheckboxFieldBulletStyled = styled.input`
  display: none;

  &:checked + ${CheckboxCheckedStyled} {
    opacity: 1;
    + ${CheckboxUncheckedStyled} {
      opacity: 0;
    }
  }
`
