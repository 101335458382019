import * as React from 'react'
import { hasWindow } from 'utils/browser/platform'
import { loadableErrorDataSource } from './loadable-error.datasource'
import { cleanCache } from '../clean-cache/clean-cache'
import { Redirect } from '@reach/router'

interface LoadableLoadingWrapperProps {
  error?: Error
  timedOut?: boolean
  pastDelay?: boolean

  children: any
}

/**
 * Use this component to wrap a Loadable loading. It enhances a loading view
 * with some recommendations from react-loadable docs.
 * https://github.com/jamiebuilds/react-loadable#avoiding-flash-of-loading-component
 *
 * It also handles errors while downloading chunks
 *
 */
export class LoadableLoadingWrapper extends React.Component<LoadableLoadingWrapperProps> {
  private sessionTokenDataSource = loadableErrorDataSource

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    if (this.props.error || this.props.timedOut) {
      const url = hasWindow() ? window.location.pathname : '-'
      const hasAlreadyReload = this.sessionTokenDataSource.getPageLoadingError().has(url)

      if (!hasAlreadyReload) {
        // Sometimes, due to some PWA update, there are some issues with loading
        // Loadable chunks. The following code cleans the cache when some
        // Loadable error happens and reload the page. If that doesn't work, the
        // user gets redirected to erro 500.

        /** tries to load this loadable again */
        this.sessionTokenDataSource.addPageLoadingError(url)

        cleanCache().then(() => {
          if (hasWindow()) {
            window.location.reload()
          }
        })
        return this.props.children
      }

      /** send user to error500 if it has already tried to load this Loadable */
      this.sessionTokenDataSource.removePageLoadingError(url)

      console.error('LoadableLoadingWrapper::error ', JSON.stringify(this.props.error))
      const toValue = { pathname: '/500' }
      return <Redirect to={toValue.pathname} />
    }
    if (this.props.pastDelay) {
      return this.props.children
    }
    return null
  }
}
