import { BodySecondary, Hbox } from 'atomic'
import * as React from 'react'
import { FacilityItemStyled } from './facility-item.style'

interface FacilityItemProps {
  icon: React.ReactElement
  text: string
  light?: boolean
}

const FacilityItem: React.FunctionComponent<FacilityItemProps> = props => (
  <FacilityItemStyled>
    <Hbox vAlign="center">
      <Hbox.Item vAlign="center" wrap>
        {props.icon}
      </Hbox.Item>
      <Hbox.Separator />
      <Hbox.Item vAlign="center">
        <BodySecondary kind={props.light ? 'light' : 'default'}>{props.text}</BodySecondary>
      </Hbox.Item>
    </Hbox>
  </FacilityItemStyled>
)

export default FacilityItem
