// tslint:disable

import { Border, Color, Spacing } from 'atomic/legacy/obj.constants'
import {
  fieldBorderCss,
  fieldBorderCssNew,
  fieldCss
} from 'atomic/legacy/obj.form/field.component.styled'
import styled from 'styled-components'
import { FaIcon } from 'atomic'

export interface CheckboxSelectWrapperStyledProps {
  isActive: boolean
}

export const CheckboxSelectWrapperStyled = styled.div`
  position: relative;
  z-index: 1;
`

export const CheckboxSelectSelectWrapperStyled = styled.div<CheckboxSelectWrapperStyledProps>`
	${fieldBorderCss}	
  position: relative;
  border-color: ${props =>
    props.isActive ? `${props.theme.color.primary} !important` : undefined};
`

export const CheckboxSelectSelectWrapperStyledNew = styled.div<CheckboxSelectWrapperStyledProps>`
  ${fieldBorderCssNew}
  position: relative;

  padding-left: 15px;
  border-color: ${props =>
    props.isActive ? `${props.theme.color.primary} !important` : undefined};
`

export const CheckboxSelectPlaceholderStyled = styled.div`
  border: 0;
  min-width: 100px;
  ${fieldCss}
`

export const CheckboxSelectPlaceholderStyledNew = styled.div`
  border: 0;
  min-width: 100px;
  color: ${Color.GrayDark} !important;
  ${fieldCss}
`

export const CheckboxSelectIconStyled = styled(FaIcon.ChevronDown)`
  position: absolute;
  top: ${Spacing.Medium};
  right: ${Spacing.Large};
  color: ${Color.GrayDark};
`

export const CheckboxSelectScrollStyled = styled.div`
  background: ${Color.White};
  position: absolute;
  top: 61px;
  max-height: 30vh;
  width: 100%;
  overflow: auto;
  padding: ${Spacing.Medium};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0 0 ${Border.Radius} ${Border.Radius};

  & > * {
    margin-top: ${Spacing.Medium};
  }
`
