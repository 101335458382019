import { StringContainer } from 'atomic/legacy/obj.abstract-container/string.container'
import SvgLocation from 'atomic/atm.svg-icon/location.component'
import { MapPlace } from 'atomic/legacy/atm.map/map.component'
import { MapLoadable } from '@root/src/components/atm.map/map.loadable'
import { useClientRect } from 'atomic/obj.custom-hooks/client-rect.hook'
import { Query } from '@root/src/data/graphql/graphql-types'
import { Body, Col, H2, Row, Separator } from 'atomic'
import { RelativeWrapper } from 'atomic/obj.wrappers'
import { graphql, StaticQuery } from 'gatsby'
import React, { useRef, useState, useEffect } from 'react'
import {
  MapGridDetailWrapperStyled,
  MapGridListWrapperStyled,
  MapGridSearchNearMeButtonWrapperStyled
} from './map-with-list-row.component.style'
import {
  getMapProps,
  getPlacesToFit,
  getPlacesToShowOnList,
  MapGridProps,
  mapToMapPlace
} from './map-with-list-row.utils'
import UnitCell from './unit-cell.component'
import { isDesktopScreenSize } from 'utils/browser'
import { mapHeight, mobileMapHeight } from 'atomic/legacy/atm.map/map.component.style'

export const MapWithListRow: React.FunctionComponent<MapGridProps> = props => {
  const [visibleBounds, setVisibleBounds] = useState<google.maps.LatLngBounds>()
  const places = props.units.map(mapToMapPlace)

  const listRef = useRef<any>()

  const placesToShowOnList = getPlacesToShowOnList(places, visibleBounds)
  const [selectedUnitId, setSelectedUnitId] = useState<string>(props.defaultSelectedUnitId)
  const selectedUnit = selectedUnitId && places.find(unit => unit.id === selectedUnitId)
  const handleBoundsChange = (bounds: google.maps.LatLngBounds) => {
    setTimeout(() => {
      if (!visibleBounds || !visibleBounds.equals(bounds)) {
        setVisibleBounds(bounds)
      }
      if (
        selectedUnit &&
        bounds.contains(selectedUnit.position) &&
        listRef.current &&
        listRef.current.scrollToItem
      ) {
        /** HACK:
         * the list always gets updated after the bounds is changed. Therefore,
         * we get the next placesToShowOnList so we can scroll to the right item
         */
        const nextPlacesToShowOnList = getPlacesToShowOnList(places, bounds)
        listRef.current.scrollToItem(
          nextPlacesToShowOnList.findIndex(place => place.id === selectedUnit.id),
          'start'
        )
      }
    }, 0)
  }

  const handleUnitSelectionOnMap = (selectedPlace: MapPlace) => {
    setSelectedUnitId(selectedPlace.id)
  }
  const handleClickListItem = (id: string) => () => {
    setSelectedUnitId(id)
  }

  const placesToFit = getPlacesToFit(props.originLocation, selectedUnit, places)
  const handleDragEnd = (bounds: google.maps.LatLngBounds) => {
    setSelectedUnitId(null)
    if (visibleBounds && !visibleBounds.equals(bounds)) {
      setVisibleBounds(bounds)
    }
  }

  const mapProps = getMapProps(
    handleBoundsChange,
    handleDragEnd,
    handleUnitSelectionOnMap,
    props,
    places,
    placesToFit,
    props.searchLocation,
    props.userLocation
  )

  const ref = useRef<HTMLDivElement>()
  const [listHeaderRect, listHeaderRef] = useClientRect()
  const headerMargins = 24 + 24
  const [mapLoadableHeight, setMapLoadableHeight] = useState('')
  useEffect(() => {
    setMapLoadableHeight(isDesktopScreenSize() ? mapHeight : mobileMapHeight)
  }, [])
  const listHeight = `calc(${mapLoadableHeight} - ${(listHeaderRect ? listHeaderRect.height : 0) +
    headerMargins}px)`

  return (
    <StaticQuery
      query={graphql`
        query MapWithListRow {
          cosmicjsInformacoesEstaticas(slug: { eq: "unidades" }) {
            metadata {
              unitNotAllowedSubtitle
              unitAllowedSubtitle
              unitSeeUnitDetail
            }
          }
        }
      `}
      render={(staticInfo: Query) => (
        <StringContainer>
          {str => (
            <Row noGutter={props.noGutter}>
              {/* LIST //////////////////////////////////////////////////////////////////////////////////////////////////// */}
              <Col xs={false} md={5} lg={4}>
                <div ref={listHeaderRef}>
                  <H2>
                    {props.originLocation
                      ? staticInfo.cosmicjsInformacoesEstaticas.metadata.unitAllowedSubtitle
                      : staticInfo.cosmicjsInformacoesEstaticas.metadata.unitNotAllowedSubtitle}
                  </H2>
                  <Body>
                    Você está vendo {placesToShowOnList.length} unidade(s) das {places.length}{' '}
                    encontradas.
                  </Body>
                  {props.originLocation && (
                    <Body>
                      As distâncias foram calculadas em relação a
                      {props.userLocation &&
                      props.originLocation.lat === props.userLocation.lat &&
                      props.originLocation.lng === props.userLocation.lng
                        ? ' você'
                        : ` "${props.searchLocationName}"`}
                      .
                    </Body>
                  )}
                  <Separator />
                </div>

                <MapGridListWrapperStyled ref={listRef} height={listHeight}>
                  {placesToShowOnList.map(place => (
                    <UnitCell
                      key={place.id}
                      onMouseEnter={str.setValueHOF(place.id)}
                      onMouseLeave={str.setValueHOF(null)}
                      onClick={handleClickListItem(place.id)}
                      unitName={place.data.name}
                      unitAddress={place.data.address}
                      link2Text={staticInfo.cosmicjsInformacoesEstaticas.metadata.unitSeeUnitDetail}
                      link2To={place.data.to}
                      selected={place.id === selectedUnitId}
                      facilities={place.data.facilities}
                      distanceInKm={place.data.distanceInKm}
                      slug={place.data.slug}
                    />
                  ))}
                </MapGridListWrapperStyled>
              </Col>

              {/* MAP //////////////////////////////////////////////////////////////////////////////////////////////////// */}
              <Col xs={12} md={7} lg={8}>
                <RelativeWrapper>
                  <div ref={ref}>
                    <MapLoadable
                      {...mapProps}
                      internalMapsKey={`${props.internalMapsKey}`}
                      highlightedMarker={str.value}
                      selectedMarker={selectedUnit}
                    />
                  </div>
                  {props.userLocation && (
                    <MapGridSearchNearMeButtonWrapperStyled onClick={props.onPanToUserClick}>
                      <SvgLocation height={48} />
                    </MapGridSearchNearMeButtonWrapperStyled>
                  )}
                </RelativeWrapper>
                <MapGridDetailWrapperStyled show={!!selectedUnit}>
                  {selectedUnit && (
                    <UnitCell
                      unitName={selectedUnit && selectedUnit.data.name}
                      unitAddress={selectedUnit.data.address}
                      link2Text={staticInfo.cosmicjsInformacoesEstaticas.metadata.unitSeeUnitDetail}
                      link2To={selectedUnit.data.to}
                      selected={true}
                      distanceInKm={selectedUnit && selectedUnit.data.distanceInKm}
                      slug={selectedUnit.data.slug}
                    />
                  )}
                </MapGridDetailWrapperStyled>
              </Col>
            </Row>
          )}
        </StringContainer>
      )}
    />
  )
}
