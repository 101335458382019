/**
 * In fact, there is more info about a geocode result.
 * Check here:
 * https://developers.google.com/maps/documentation/geocoding/intro#Results
 */
export interface GeocodeResult {
  formatted_address: string;
  geometry: {
    bounds: {
      northeast: google.maps.LatLngLiteral;
      southwest: google.maps.LatLngLiteral;
    };
    location: google.maps.LatLngLiteral;
  };
}

export interface GeocodeBounds {
  south: number;
  west: number;
  north: number;
  east: number;
}

export class GoogleMapsService {
  constructor(private readonly apiKey: string) {}

  async geocode(address: string, bounds?: GeocodeBounds) {
    console.log('TCL: GoogleMapsService -> geocode -> address', address);
    try {
      const geocoder = new google.maps.Geocoder();
      return new Promise<GeocodeResult>(resolve => {
        geocoder.geocode(
          {
            bounds,
            address,
            region: 'br'
          },
          response => {
            if (!response || response.length === 0) {
              // TODO: MAP_IMPROVEMENT - do something (ex: flash message) (check if this can happen)
              console.log(
                'GoogleMapsService -> geocode -> response -> 0 results',
                response
              );
              resolve(null)
              // return;
            }
            if (response.length > 1) {
              // TODO: MAP_IMPROVEMENT - do something (ex: modal to choose) (check if this can happen)
              console.log(
                'GoogleMapsService -> geocode -> response -> >1 results',
                response
              );
            }

            const result: google.maps.GeocoderResult = response[0];
            if (!result) {
              resolve(null)
            }

            try {
              resolve({
                formatted_address: result.formatted_address,
                geometry: {
                  bounds: {
                    northeast: {
                      lat: result.geometry.bounds ? result.geometry.bounds.getNorthEast().lat() : 0,
                      lng: result.geometry.bounds ? result.geometry.bounds.getNorthEast().lng() : 0
                    },
                    southwest: {
                      lat: result.geometry.bounds ? result.geometry.bounds.getSouthWest().lat() : 0,
                      lng: result.geometry.bounds ? result.geometry.bounds.getSouthWest().lng() : 0
                    }
                  },
                  location: {
                    lat: result.geometry.location.lat(),
                    lng: result.geometry.location.lng()
                  }
                }
              });
            } catch (error) {
              console.log('GoogleMapsService:Geocode service error:', error);
              resolve(null)
            }
          }
        );
      });
    } catch (error) {
      // TODO: MAP_IMPROVEMENT - handle error
      console.log('GoogleMapsService:Geocode service error:', error);
      return null;
    }
  }

  get key() {
    return this.apiKey;
  }
}
