import { ThumbShimmerBoxStyled } from 'atomic/legacy/mol.shimmer/shimmer.component.style'
import * as React from 'react'
import { mapHeight } from './map.component.style'

interface MapShimmerProps {}

const MapShimmer: React.FunctionComponent<MapShimmerProps> = _props => {
  return <ThumbShimmerBoxStyled height={mapHeight} width={'100%'} />
}

export default MapShimmer
