import { Spacing, TransitionDuration } from 'atomic'
import { onlyMobileStyle } from 'atomic/legacy/obj.mixin'
import styled, { keyframes } from 'styled-components'

export const enter = keyframes`
  from {
    opacity: 0;
    height: 0;
  }

  to {
    opacity: 1;
    height: 180px;
  }
`
export const exit = keyframes`
  from {
    opacity: 1;
    height: 180px;
  }

  to {
    opacity: 0;
    height: 0;
  }
`

export const MapGridListWrapperStyled = styled.div<{ height: string }>`
  max-height: ${props => props.height};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overscroll-behavior: contain;
`

export const MapGridSearchNearMeButtonWrapperStyled = styled.div`
  position: absolute;
  bottom: 198px;
  right: 7px;
`

export const MapGridDetailWrapperStyled = styled.div<{ show: boolean }>`
  ${onlyMobileStyle}
  width: 100%;
  animation: ${props => (props.show ? enter : exit)} ${TransitionDuration} linear;
  position: absolute;
  bottom: -${Spacing.Medium};
  left: 0;
`
