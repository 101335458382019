import styled from 'styled-components'
import { Border, Color, Spacing } from 'atomic'

const margin = Spacing.Medium
export const UnitCellStyled = styled.div`
  border-radius: ${Border.Radius};
  box-shadow: 0px 4px 8px ${Color.Gray};
  padding: ${Spacing.Large};
  background: ${Color.White};
  height: calc(100% - ${margin});
  margin-bottom: ${margin};
  display: flex;
  flex-direction: column;
`

export const UnitCellStyledNew = styled.div`
  border-radius: ${Border.Radius};

  padding: ${Spacing.Large};
  background: ${Color.White};
  height: calc(100% - ${margin});
  margin-bottom: ${margin};
  display: flex;
  flex-direction: column;

  img {
    width: 23px;
  }
`

export const FacilitiesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
`
