import React from 'react'

const SvgLocation = props => (
  <svg viewBox="0 0 37 37" {...props}>
    <defs>
      <rect id="Location_svg__b" x={0} y={0} width={29} height={29} rx={2} />
      <filter x="-22.4%" y="-19%" width="144.8%" height="144.8%" filterUnits="objectBoundingBox" id="Location_svg__a">
        <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(4 3)">
        <use fill="#000" filter="url(#Location_svg__a)" xlinkHref="#Location_svg__b" />
        <use fill="#FFF" xlinkHref="#Location_svg__b" />
      </g>
      <g transform="translate(11 10)">
        <circle stroke="#D1D1D1" cx={8} cy={8} r={5.5} />
        <circle stroke="#FFF" fill="#666" cx={8} cy={8} r={4} />
        <path fill="#D1D1D1" d="M7 0h2v2H7zM7 14h2v2H7zM14 7h2v2h-2zM0 7h2v2H0z" />
      </g>
    </g>
  </svg>
)

export default SvgLocation
