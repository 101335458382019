import styled from 'styled-components'
import { Spacing } from 'atomic'
import { FontSize } from 'atomic/legacy/obj.constants'


export const FacilityItemStyled = styled.div`
  margin-bottom: ${Spacing.Small};

  label {
    color: #302A38;
  }

  @media (min-width: 768px) {
    label{
      font-size: ${FontSize.Small};

    }
  }
`

export const FacilityItemStyledNew = styled.span`
  background: #e6f3f5;
  border-radius: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  width: auto;
  font-family: 'Signika';
  font-size: 12px;
  line-height: 18px;
  color: #5c5c5c;
  margin-right: 5px;
  margin-bottom: 5px;
`
