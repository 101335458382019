import React from 'react'

export const SvgIcPlusIcon = props => (
  <svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 8a.846.846 0 0 1-.846.846H8.846v4.308a.846.846 0 0 1-1.692 0V8.846H2.846a.846.846 0 0 1 0-1.692h4.308V2.846a.846.846 0 0 1 1.692 0v4.308h4.308c.467 0 .846.379.846.846z"
      fill="#000"
    />
    <mask id="PlusIcon_svg__a" maskUnits="userSpaceOnUse" x={2} y={2} width={12} height={12}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8a.846.846 0 0 1-.846.846H8.846v4.308a.846.846 0 0 1-1.692 0V8.846H2.846a.846.846 0 0 1 0-1.692h4.308V2.846a.846.846 0 0 1 1.692 0v4.308h4.308c.467 0 .846.379.846.846z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#PlusIcon_svg__a)">
      <path fill="#464646" d="M0 0h16v16H0z" />
    </g>
  </svg>
)
