import { useEffect, useState } from 'react'
import { LocationService } from '../legacy/atm.map/location'

export const useUserLocation = () => {
  const [userLocation, setUserLocation] = useState<google.maps.LatLngLiteral>()
  useEffect(() => {
    const getCurrentLocation = () =>
      LocationService.currentLocation({ timeout: 5000, maximumAge: 0 }).then(position => {
        const { latitude, longitude } = position.coords
        setUserLocation({ lat: latitude, lng: longitude })
      })

    getCurrentLocation()

    LocationService.getPermissionStatus().then(permissionStatus => {
      if (!permissionStatus) {
        return;
      }
      permissionStatus.onchange = function() {
        switch (this.state) {
          case 'denied':
          case 'prompt':
            setUserLocation(null)
            break
          case 'granted':
          default:
            getCurrentLocation()
            break
        }
      }
    })
  }, [])

  return userLocation
}
