import { Col, Row } from 'atomic'
import { CheckboxSelectField } from '@root/src/components/atm.checkbox-select'
import { Segmented } from 'atomic/legacy/atm.segmented'
import { Separator } from 'atomic/legacy/obj.box'
import { Form } from 'atomic/legacy/obj.form'
import { GeocodeResult, GoogleMapsService } from 'atomic/legacy/atm.map/utils'
import FlashDispatcherService from '@root/src/components/legacy/obj.flash-wrapper/flash-dispatcher.service'
import { SearchField } from '@root/src/components/org.menu/components/search-field.component'
import React, { useEffect, useRef, useState } from 'react'
import { isNullOrUndefined } from 'util'
import { Facility } from './unit-cell.component'
import { getBoundsFromLocation, getGoogleMapURL } from 'atomic/legacy/atm.map/utils/map-utils'
import { ScriptLoader } from 'atomic/legacy/obj.script-loader'

const flashDispatcher = FlashDispatcherService
/**
 * The values are coupled with the order declared on the view
 */
export enum MapListSelect {
  list = 0,
  map = 1
}

interface MapSettingsProps {
  /**
   * Whether the facility filter should be shown.
   * Sometimes you don't want to show it: https://digitalfleury.atlassian.net/secure/RapidBoard.jspa?rapidView=112&projectKey=CMS&modal=detail&selectedIssue=CMS-230
   */
  showFacilityFilter?: boolean

  userLocation: google.maps.LatLngLiteral

  googleMapsKey: string
  onSearchSuccess: (result: GeocodeResult) => void

  mapListSelect?: MapListSelect
  onMapListSegmentedControlChange?: (selection: MapListSelect) => void

  facilities: Facility[]
  onFilterValueChange?: (value: string[]) => void
}

export const MapSettingsRow: React.FunctionComponent<MapSettingsProps> = props => {
  const googleMapsService = useRef<GoogleMapsService>()
  useEffect(() => {
    googleMapsService.current = new GoogleMapsService(props.googleMapsKey)
  }, [])

  const [loading, setLoading] = useState(false)

  const handleSearchSubmit = async form => {
    const search = form.data.search

    try {
      if (search.length > 0) {
        setLoading(true)
        const result: GeocodeResult = await googleMapsService.current.geocode(
          search,
          props.userLocation && getBoundsFromLocation(props.userLocation, 30 * 1000)
        )

        setLoading(false)

        if (!result) {
          flashDispatcher.dispatchMessage('Endereço não encontrado', 'alert')
          return
        }
        props.onSearchSuccess(result)
      }
    } catch (error) {
      setLoading(false)
      flashDispatcher.dispatchMessage(error.message, 'alert')
    }
  }

  const options = props.facilities.map(item => ({
    id: item.facilityName,
    name: item.facilityName,
    iconSrc: item.iconSrc
  }))

  return (
    <Row bottom="xs" between="xs">
      <Col xs={12} md={4}>
        <ScriptLoader src={getGoogleMapURL(props.googleMapsKey)}>
          {scriptState =>
            scriptState.done && (
              <Form id="form-map-settings-row" onSubmit={handleSearchSubmit}>
                <Form.Field name="search">
                  <SearchField
                    id="input-search-field"
                    loading={loading}
                    autoFocus={false}
                    onBlur={null}
                    placeholder={
                      process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann'
                        ? 'O que você procura?'
                        : 'Digite um endereço'
                    }
                  />
                </Form.Field>
              </Form>
            )
          }
        </ScriptLoader>
        <Separator />
      </Col>
      {!isNullOrUndefined(props.mapListSelect) && (
        <Col xs={false} md={true}>
          <Segmented
            key={props.mapListSelect}
            onChange={props.onMapListSegmentedControlChange}
            defaultIndex={props.mapListSelect}
          >
            <Segmented.Item id="segmented-item-view-list">Lista</Segmented.Item>
            <Segmented.Item id="segmented-item-view-map">Mapa</Segmented.Item>
          </Segmented>
          <Separator />
        </Col>
      )}
      {options && options.length > 0 && props.showFacilityFilter && (
        <Col xs={12} md={4}>
          <CheckboxSelectField
            label="Filtro por facilidade"
            items={options}
            onValueChange={props.onFilterValueChange}
          />
          <Separator />
        </Col>
      )}
    </Row>
  )
}

MapSettingsRow.defaultProps = {
  showFacilityFilter: true
}
