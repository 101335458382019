import * as React from 'react'

export interface ScriptLoaderProps {
  /** Event. Called when the script successfully loads */
  onLoad?: () => void
  /** Event. Called when the script fails to load */
  onError?: () => void
  /** Specifies the URL of an external script file */
  src: string
  /** Specifies that the script is executed asynchronously (only for external scripts) */
  async?: boolean
  /**
   * Object with other attributes to be copied to <script> tag.
   * Exceptions: 'src', 'async', 'load' and 'error'.
   */
  attributes?: { [key: string]: any }
  children?: (state: ScriptLoaderState) => React.ReactNode
}

export interface ScriptLoaderState {
  done: boolean
  error: boolean
}

export class ScriptLoader extends React.PureComponent<ScriptLoaderProps, ScriptLoaderState> {
  static defaultProps = {
    async: false
  }

  private node: HTMLScriptElement

  constructor(props: ScriptLoaderProps) {
    super(props)
    this.state = {
      done: false,
      error: false
    }
  }

  componentDidMount() {
    // guard
    if (!this.node && typeof document !== 'undefined') {
      this.buildScriptNode()
    }
  }

  componentWillUnmount() {
    if (this.node) {
      this.clearNodeCallbacks()
      document.head.removeChild(this.node)
    }
  }

  render() {
    return <>{(this.props.children && this.props.children(this.state)) || null}</>
  }

  private buildScriptNode() {
    const { onLoad, onError, attributes, src, async } = this.props

    if (this.isScriptLoaded(src)) {
      this.setState({ done: true })
      return
    }

    this.node = document.createElement('script')

    this.node.src = src
    this.node.async = async

    if (attributes) {
      Object.keys(attributes).forEach(key => this.node.setAttribute(key, attributes[key]))
    }

    this.node.onload = this.handleLoad(onLoad)
    this.node.onerror = this.handleError(onError)

    document.head.appendChild(this.node)
  }

  private handleLoad = (onLoad = () => undefined) => {
    return () => {
      onLoad()
      this.setState({ done: true })
      this.clearNodeCallbacks()
    }
  }

  private handleError = (onError = () => undefined) => {
    return () => {
      onError()
      this.setState({ done: true, error: true })
      this.clearNodeCallbacks()
    }
  }

  private isScriptLoaded(src) {
    return document.querySelector(`script[src="${src}"]`) ? true : false;
  }

  private clearNodeCallbacks() {
    this.node.onload = undefined
    this.node.onerror = undefined
  }
}
