export interface LatLng {
  lat: number
  lng: number
}

interface PermissionStatus {
  onchange: any
  readonly state: any
  addEventListener: any
  removeEventListener: any
}

export class LocationService {
  static async getPermissionStatus(): Promise<PermissionStatus> {
    if (typeof navigator === 'undefined' || typeof (navigator as any).permissions === 'undefined') {
      return null
    }

    return (navigator as any).permissions.query({ name: 'geolocation' })
  }

  static async currentLocation(options?: PositionOptions): Promise<any> {
    if (typeof navigator === 'undefined') {
      return null
    }

    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options)
    })
  }

  static async isAllowed(): Promise<any> {
    if (typeof navigator === 'undefined') {
      return null
    }

    return new Promise((resolve, reject) => {
      const nav: any = navigator
      if (!nav.permissions) {
        resolve(false)
        return
      }

      nav.permissions.query({ name: 'geolocation' }).then(
        (permissionStatus: PermissionStatus) => {
          resolve(permissionStatus.state === 'granted')
        },
        error => reject(error)
      )
    })
  }
}
