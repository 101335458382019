import * as React from 'react'

interface StringContainerStateProps {
  defaultValue?: string
  children: (props: StringContainerChildrenProps) => JSX.Element
}

interface StringContainerState {
  value?: string
}

export interface StringContainerChildrenProps {
  value?: string
  setValue: (value) => void
  setValueHOF: (value) => () => void
}

export class StringContainer extends React.Component<StringContainerStateProps, StringContainerState> {
  constructor(props: any) {
    super(props)
    this.state = { value: this.props.defaultValue }
  }

  render() {
    return this.props.children({
      value: this.state.value,
      setValue: this.setValue,
      setValueHOF: this.setValueHOF
    })
  }

  private setValue = (value: string) => {
    this.setState({ value })
  }

  private setValueHOF = (value: string) => () => {
    this.setState({ value })
  }
}
