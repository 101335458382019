import { MapPlace, MapPlaceGroup, MapProps } from 'atomic/legacy/atm.map/map.component'
import { CosmicjsInformacoesEstaticas } from '@root/src/data/graphql/graphql-types'
import { Facility } from './unit-cell.component'
import { diactriclessKebabCase } from 'utils/string'

export interface MapGridUnit {
  lat?: number
  lng?: number
  id?: string
  name?: string
  slug: string
  to: string
  address?: string
  facilities: Facility[]
  distanceInKm?: number
}

export interface MapGridProps {
  googleMapsKey: string
  cosmicjsMapas: CosmicjsInformacoesEstaticas
  units: MapGridUnit[]

  searchLocationName?: string
  searchLocation?: google.maps.LatLngLiteral
  userLocation?: google.maps.LatLngLiteral

  // the origin (to which the distance will be computed)
  originLocation?: google.maps.LatLngLiteral

  onPanToUserClick: () => void

  internalMapsKey: string | number

  defaultSelectedUnitId?: string
  noGutter?: boolean
}

export const getPlacesToFit = (
  locationToBeUsed: google.maps.LatLngLiteral,
  selectedUnit: MapPlace,
  places: MapPlace[]
) => {
  if (!locationToBeUsed && !selectedUnit) {
    return places
  }

  const placesToShow = []

  if (locationToBeUsed) {
    placesToShow.push(...places.slice(0, getPlacesToFitSliceIndex(places)))
    placesToShow.push({ id: 'locationToBeUsed', position: locationToBeUsed } as MapPlace)
  }

  return placesToShow
}

/**
 * get index for which there is enough places to be shown considering their distance:
 *   - all `places` distance should be smaller than `maxDistance`
 *   - and (`places.length` <= `maxItemsToShow` + 1)
 *   - and show at least the nearest item
 */
const getPlacesToFitSliceIndex = (places: MapPlace[]) => {
  const maxItemsToShow = 5
  const maxDistance = 5

  return Math.max(
    1,
    Math.min(
      places.findIndex(place => place.data.distanceInKm > maxDistance),
      maxItemsToShow
    )
  )
}

export const getMapProps = (
  handleBoundsChange: (bounds: google.maps.LatLngBounds) => void,
  handleDragEnd: (bounds: google.maps.LatLngBounds) => void,
  handleUnitSelection: (place: MapPlace) => void,
  props: MapGridProps,
  places: MapPlace[],
  placesToFit: MapPlace[],
  searchLocation: google.maps.LatLngLiteral,
  userLocation: google.maps.LatLngLiteral
) => {
  const icons = props.cosmicjsMapas.metadata
  const mapProps: MapProps = {
    googleMapsKey: props.googleMapsKey,
    initialPosition: { placesToFit },
    placesGroups: [
      {
        places,
        iconUrl: icons.unitPin.url,
        selectedIconUrl: icons.selectedUnitPin.url,
        hoverIconUrl: icons.unitPinHover.url
      } as MapPlaceGroup
    ],
    userLocation: userLocation && {
      iconUrl: icons.userPin.url,
      position: userLocation
    },
    searchLocation: searchLocation && {
      position: searchLocation,
      iconUrl: icons.searchPin.url
    },
    onBoundsChange: handleBoundsChange,
    onDragEnd: handleDragEnd,
    onMarkerClick: handleUnitSelection
  }
  return mapProps
}

export const getPlacesToShowOnList = (
  places: MapPlace[],
  visibleBounds: google.maps.LatLngBounds
) => {
  return visibleBounds ? places.filter(place => visibleBounds.contains(place.position)) : places
}

/**
 * This gets the locations considering:
 *  - whether the user searched something
 *  - the user position
 *  - if any filter was selected
 *
 * ps: if the user hasn't searched something neither its position is known,
 * then the place list will be sorted alphabetically
 *
 */
export const getFilteredAndSortedUnits = (
  units: MapGridUnit[],
  locationToBeUsed: google.maps.LatLngLiteral,
  selectedFilter: string[]
) => {
  const filteredUnits: MapGridUnit[] =
    selectedFilter && selectedFilter.length
      ? units.filter(unit =>
          selectedFilter.every(filter =>
            unit.facilities.map(facility => facility.facilityName).includes(filter)
          )
        )
      : units

  if (locationToBeUsed) {
    filteredUnits.sort((p1, p2) => p1.distanceInKm - p2.distanceInKm)
  } else {
    filteredUnits.sort((p1, p2) =>
      diactriclessKebabCase(p1.name) > diactriclessKebabCase(p2.name) ? 1 : -1
    )
  }

  return filteredUnits
}

export const mapToMapPlace = (unit: MapGridUnit) => {
  return {
    id: unit.id,
    position: { lat: unit.lat, lng: unit.lng },
    data: {
      ...unit
    }
  } as MapPlace
}
