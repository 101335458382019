import { IconTextCell } from '@components/atm.icon-text-cell/icon-text-cell.component'
import React, { useEffect, useRef, useState } from 'react'
import { Button, InputLabel } from 'atomic'
import { CheckboxField } from 'atomic/legacy/atm.checkbox'
import { ForcedFade } from 'atomic/legacy/obj.animation/animation.component.style'
import { Separator } from 'atomic/legacy/obj.box'
import { useBooleanState } from 'atomic/obj.boolean-state/boolean-state.hook'
import { InlineBlockStyled } from 'atomic/obj.wrappers'
import {
  CheckboxSelectIconStyled,
  CheckboxSelectPlaceholderStyled,
  CheckboxSelectPlaceholderStyledNew,
  CheckboxSelectScrollStyled,
  CheckboxSelectSelectWrapperStyled,
  CheckboxSelectSelectWrapperStyledNew,
  CheckboxSelectWrapperStyled
} from './checkbox-select.component.style'

export interface CheckboxSelectItem {
  name: string
  iconSrc?: string
  id: string
}

export interface CheckboxSelectProps {
  label?: string
  onValueChange?: (value: string[]) => void
  items: CheckboxSelectItem[]
  initialValue?: string[]
}

export const CheckboxSelectField: React.FunctionComponent<CheckboxSelectProps> = props => {
  const { label, initialValue, onValueChange, items } = props

  const [isActive, componentNode, fieldNode] = useSelectListVisibilityState()
  const [selection, handleSelection, handleReset] = useSelectListSelectionState(
    initialValue,
    onValueChange
  )

  return (
    <CheckboxSelectWrapperStyled ref={componentNode}>
      {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() !== 'weinmann' && label && (
        <InputLabel>{label}</InputLabel>
      )}

      {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' ? (
        <CheckboxSelectSelectWrapperStyledNew
          id="checkoox-select-facilities"
          ref={fieldNode}
          isActive={isActive}
        >
          <CheckboxSelectPlaceholderStyledNew>
            {getPlaceholder(selection.length)}{' '}
          </CheckboxSelectPlaceholderStyledNew>
          <CheckboxSelectIconStyled />
        </CheckboxSelectSelectWrapperStyledNew>
      ) : (
        <CheckboxSelectSelectWrapperStyled
          id="checkoox-select-facilities"
          ref={fieldNode}
          isActive={isActive}
        >
          <CheckboxSelectPlaceholderStyled>
            {getPlaceholder(selection.length)}{' '}
          </CheckboxSelectPlaceholderStyled>
          <CheckboxSelectIconStyled />
        </CheckboxSelectSelectWrapperStyled>
      )}

      {isActive && (
        <ForcedFade show={isActive}>
          <CheckboxSelectScrollStyled>
            <Button id="button-checkbox-select" kind="link" onClick={handleReset}>
              Limpar opções
            </Button>
            <Separator />
            {items.map(item => (
              <CheckboxField
                id={item.id}
                key={item.id}
                checked={selection.includes(item.id)}
                onValueChange={handleSelection}
              >
                <InlineBlockStyled verticalAlign={'bottom'}>
                  <IconTextCell text={item.name} iconSrc={item.iconSrc} />
                </InlineBlockStyled>
              </CheckboxField>
            ))}
          </CheckboxSelectScrollStyled>
        </ForcedFade>
      )}
    </CheckboxSelectWrapperStyled>
  )
}

function useSelectListVisibilityState(): [
  boolean,
  React.MutableRefObject<any>,
  React.MutableRefObject<any>
] {
  const { value, setFalse, toggle } = useBooleanState(false)
  const isActive = value
  const componentNode = useRef<any>()
  const fieldNode = useRef<any>()

  useEffect(() => {
    const handleComponentClick = e => {
      if (fieldNode.current.contains(e.target)) {
        toggle()
        return
      }
      if (!componentNode.current.contains(e.target)) {
        setFalse()
        return
      }
    }

    document.addEventListener('mousedown', handleComponentClick)
    return () => {
      document.removeEventListener('mousedown', handleComponentClick)
    }
  }, [toggle, setFalse])

  return [isActive, componentNode, fieldNode]
}

const useSelectListSelectionState = (initialValue, onValueChange) => {
  const [selection, setSelection] = useState(initialValue || [])

  const handleSelection = (id: string, checked: boolean) => {
    const set = new Set(selection)
    if (checked) {
      set.add(id)
    } else {
      set.delete(id)
    }
    const newValue = Array.from(set)
    setSelection(newValue)
    if (onValueChange) {
      onValueChange(newValue)
    }
  }

  const handleReset = () => {
    const newValue = []
    setSelection(newValue)
    if (onValueChange) {
      onValueChange(newValue)
    }
  }

  return [selection, handleSelection, handleReset]
}

const getPlaceholder = (selectionLength: number) => {
  switch (selectionLength) {
    case 0:
      return process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann'
        ? 'Filtrar por facilidade'
        : 'Selecione'
    case 1:
      return '1 item selecionado'
    default:
      return `${selectionLength} itens selecionados`
  }
}
