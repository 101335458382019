import { defaultLoadableConfig } from '@root/src/utils/loadable/default-config'
import { LoadableLoadingWrapper } from '@root/src/utils/loadable/loadable-loading-wrapper.component'
import * as React from 'react'
import * as Loadable from 'react-loadable'
import MapShimmer from 'atomic/legacy/atm.map/map-shimmer.component'

export const MapLoadable = Loadable({
  loader: () => import('atomic/legacy/atm.map/map.component'),
  loading: props => (
    <LoadableLoadingWrapper {...props}>
      <MapShimmer />
    </LoadableLoadingWrapper>
  ),
  ...defaultLoadableConfig
})
