import { Col, H1, Row, Separator } from 'atomic'
import { Modal } from 'atomic/legacy/obj.modal'
import { WhiteBackground } from '@root/src/components/atm.background/background.styled'
import { getDistanceFromLatLonInKm } from 'atomic/legacy/atm.map/utils/map-utils'
import { EventTimestampContainer } from 'atomic/legacy/obj.abstract-container'
import { Query } from '@root/src/data/graphql/graphql-types'
import { isDesktopScreenSize } from 'utils/browser'
import { appPaths, getPathUrl, getUnitDetailPageUrl } from 'utils/path'
import { isDoctorUrl } from 'utils/url'
import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import { getUniqueFacilities } from '../unit.utils'
import { useMapInteractions } from './map-interactions.hook'
import { MapSettingsRow } from './map-settings-row.component'
import { MapWithListRow } from './map-with-list-row.component'
import { getFilteredAndSortedUnits, MapGridUnit } from './map-with-list-row.utils'
import { Facility } from './unit-cell.component'

interface MapModalProps {
  location: Location

  /** Units to be shown */
  unitIds: string[]
  defaultSelectedUnitId?: string

  /**
   * Whether the facility filter should be shown.
   */
  showFacilityFilter?: boolean

  mapModalOpen: boolean
  onClose: () => void
}

const MapModal: React.FunctionComponent<MapModalProps> = props => {
  const [
    origin,
    userLocation,
    handlePanToUser,
    searchLocation,
    handleSearchSuccess,
    selectedFilter,
    handleFilterSelectionChange
  ] = useMapInteractions()

  return (
    <StaticQuery
      query={graphql`
        query MapModalDetail {
          site {
            siteMetadata {
              siteUrl
              googleMapsKey
            }
          }

          allUnidades {
            nodes {
              id
              idLegado
              nome
              slug
              endereco
              longitude
              latitude
              facilidades {
                facilidade
                icone {
                  url
                  imgix_url
                }
              }
            }
          }

          cosmicjsInformacoesEstaticas(slug: { eq: "unidades" }) {
            id
            metadata {
              unitMapModalTitle
              unitPin {
                url
                imgix_url
              }
              selectedUnitPin {
                url
                imgix_url
              }
              unitPinHover {
                url
                imgix_url
              }
              userPin {
                url
                imgix_url
              }
              searchPin {
                url
                imgix_url
              }
            }
          }
        }
      `}
      render={(data: Query) => {
        const locationToBeUsed =
          origin === 'search' ? searchLocation && searchLocation.geometry.location : userLocation
        const units: MapGridUnit[] = data.allUnidades.nodes.map(unit => ({
          lat: Number(unit.latitude),
          lng: Number(unit.longitude),
          id: String(unit.idLegado),
          slug: unit.slug,
          to: getUnitDetailPageUrl(
            getPathUrl(appPaths.units.path, isDoctorUrl(props.location.pathname)),
            unit.slug
          ),
          name: unit.nome,
          address: unit.endereco,
          facilities: unit.facilidades
            ? unit.facilidades.map(facility => ({
                facilityName: facility?.facilidade,
                iconSrc: facility?.icone?.imgix_url
              }))
            : [],
          distanceInKm:
            locationToBeUsed &&
            getDistanceFromLatLonInKm(
              { lat: Number(unit.latitude), lng: Number(unit.longitude) },
              locationToBeUsed
            )
        }))

        const unitsFilteredById = units.filter(unit => props.unitIds.indexOf(unit.id) !== -1)

        const allFacilidades = unitsFilteredById.reduce(
          (acc, curr) => acc.concat(curr.facilities),
          [] as Facility[]
        )
        const desktop = isDesktopScreenSize()

        const filteredAndSortedUnits = getFilteredAndSortedUnits(
          unitsFilteredById,
          locationToBeUsed,
          selectedFilter
        )
        return (
          <Modal opened={props.mapModalOpen} onClose={props.onClose} noPadding={!desktop}>
            <EventTimestampContainer>
              {event => (
                <>
                  {desktop && (
                    <WhiteBackground>
                      <Row mt>
                        <Col xs={12}>
                          <H1>{data.cosmicjsInformacoesEstaticas.metadata.unitMapModalTitle}</H1>
                        </Col>
                      </Row>
                      <MapSettingsRow
                        userLocation={userLocation}
                        facilities={getUniqueFacilities(allFacilidades)}
                        googleMapsKey={data.site.siteMetadata.googleMapsKey}
                        showFacilityFilter={props.showFacilityFilter}
                        onSearchSuccess={handleSearchSuccess(event.setTimestamp)}
                        onFilterValueChange={handleFilterSelectionChange(event.setTimestamp)}
                      />
                    </WhiteBackground>
                  )}
                  <MapWithListRow
                    cosmicjsMapas={data.cosmicjsInformacoesEstaticas}
                    internalMapsKey={event.timestamp}
                    units={filteredAndSortedUnits}
                    googleMapsKey={data.site.siteMetadata.googleMapsKey}
                    onPanToUserClick={handlePanToUser(event.setTimestamp)}
                    searchLocation={searchLocation && searchLocation.geometry.location}
                    searchLocationName={searchLocation && searchLocation.formatted_address}
                    userLocation={userLocation}
                    originLocation={locationToBeUsed}
                    defaultSelectedUnitId={props.defaultSelectedUnitId}
                  />
                </>
              )}
            </EventTimestampContainer>
            {desktop && <Separator />}
          </Modal>
        )
      }}
    />
  )
}

MapModal.defaultProps = {
  showFacilityFilter: true
}

export default MapModal
