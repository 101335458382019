import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { MultipleCollumnWrapper } from '@app/components/atm.wrapper/wrapper.component'
import { SEO } from '@app/components/legacy/mol.seo/seo.component'
import { SchedulingModal } from '@app/components/mol.scheduling-modal/scheduling-modal.component'
import TitleWithBreadcrumbRow from '@app/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { ClientDoctorSelectContext } from '@app/context/client-doctor-select'
import { PageProps } from '@app/utils/local-types'
import { appPaths, getCanonicalUrl, getPathUrl } from 'utils/path'
import { diactriclessKebabCase } from 'utils/string'
import {
  getClientAndDoctorUrl,
  //getDetailPageUrl,
  getSearchQueryParam,
  isDoctorUrl
} from 'utils/url'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { Query, Unidades, UnidadesHorariosAtendimento } from '@root/src/data/graphql/graphql-types'
import {
  Body,
  BodySecondary,
  Button,
  Col,
  Divisor,
  FontSize,
  Grid,
  H2,
  H3,
  Row,
  Separator
} from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import { BooleanContainer } from 'atomic/legacy/obj.abstract-container'
import { useBooleanState } from 'atomic/obj.boolean-state/boolean-state.hook'
import { BannerCarousel } from 'atomic/org.banner-carousel/banner-carousel.component'

import FacilityItem from './component/facility-item.component'
import MapModal from './component/map-modal.component'
import { useMediaQuery, useTheme } from '@material-ui/core'

// import Carousel from 'react-multi-carousel'

// import { BannerCarousel } from '../../components/BannerCarousel/bannerCarousel'
//import { CardsSectionUnitsImages } from '@root/src/components/org.cards-section-unit-images/org.cards-section-unit-images'
//import { ContainerUnitPhotos } from './unit-styles'

export interface UnitDetailTemplateProps {
  unidade: Unidades
}

const UnitDetailTemplate: React.FunctionComponent<PageProps<
  UnitDetailTemplateProps,
  Query
>> = props => {
  const [selectedUnitId, setSelectedUnitId] = useState<string>()
  const { value, setTrue, setFalse } = useBooleanState(false)
  const [mapModalOpen, showModal, hideModal] = [value, setTrue, setFalse]

  const handleShowUnitOnMap = (id: string) => () => {
    setSelectedUnitId(id)
    showModal()
  }

  const allUnitIds = props.data.allUnidades.edges.map(item => String(item.node.idLegado))
  const unidade = props.pageContext.unidade

  console.log(props)
  

  // BUSINESS-RULE: exams are separated by ";"
  const exams = unidade.exames.split(';')
  
  let banners = undefined as { image: string }[]
  if (unidade.fotos) {
    banners = unidade.fotos.map(foto => {
      return {
        image: foto.imagem.imgix_url
      }
    })
  }

  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)

  const breadcrumbAdditionalDictionary = {}
  breadcrumbAdditionalDictionary[`/${diactriclessKebabCase(unidade.slug)}`] = unidade.nome
  const staticInfo = props.data.cosmicjsInformacoesEstaticas.metadata
  // const servicos = unidade.servicosDiferenciados
  //   ? unidade.servicosDiferenciados.map(servico => servico.title)
  //   : []
  const seoData = {
    canonicalUrl: getCanonicalUrl(
      props.data.site.siteMetadata.siteUrl,
      appPaths.units.path,
      diactriclessKebabCase(unidade.nome)
    ),
    title: `Unidade ${unidade.nome} › Laboratórios | Fleury Medicina e Saúde`,
    image: unidade.fotos[0].imagem.url,
    imageAlt: `Foto da unidade ${unidade.nome}`
    // description: `A unidade ${unidade.nome} está situada em ${unidade.endereco}${
    //   servicos.length > 0 && servicos[0].length > 0
    //     ? `, conta com ${getServicosDiferenciadosString(servicos)}`
    //     : ''
    // }.
    // Confira o horário de atendimento para análises clínicas e para agendamento de exames.`
  }

  const horarios = Object.keys(unidade.horariosAtendimento)
    .map(key => ({
      name: getWorkingHourNameFromKey(key as keyof UnidadesHorariosAtendimento),
      horarios: unidade.horariosAtendimento[key]
    }))
    .filter(item => item.horarios && item.horarios[0]?.horario_inicial)

  console.log('horarios', horarios)
  const facilities =
    unidade.facilidades && unidade.facilidades.filter(facility => facility?.icone?.imgix_url)
  const isDoctor = isDoctorUrl(props.location.pathname)

  //const location = `${unidade.endereco},${unidade.bairro},${unidade.estado},${unidade.cep}`

  const theme = useTheme()
  const downOfMd = useMediaQuery(theme.breakpoints.down(1024))

  return (
    <ClientDoctorSelectContext.Provider value={{ clientUrl: clientPath, doctorUrl: doctorPath }}>
      <IndexLayout location={props.location}>
        <SEO socialMedia={seoData} />
        <Grid>
          <TitleWithBreadcrumbRow
            title={unidade.nome}
            addtionalDictionary={breadcrumbAdditionalDictionary}
          />
          {banners && (
            <Row mb>
              <Col xs>
                <BannerCarousel banners={banners} />
              </Col>
            </Row>
          )}
          <Row mt>
            <Col xs={12} md={7}>
              <Row mb>
                <Col xs>
                  <H2> {staticInfo.enderecoTitulo}</H2>
                  <Body>{`${unidade.endereco} - ${unidade.bairro} - ${unidade.estado}, CEP: ${unidade.cep}`}</Body>
                </Col>
              </Row>
              <Row mb>
                <Col xs={12} md={4}>
                  <Button
                    id="button-see-on-map-unit-detail"
                    kind="secondary"
                    onClick={handleShowUnitOnMap(String(unidade.idLegado))}
                    expanded
                  >
                    Ver no mapa
                  </Button>
                </Col>
              </Row>
              {horarios.length > 0 && (
                <>
                  <Row mb>
                    <Col xs={12}>
                      <Divisor />
                    </Col>
                  </Row>
                  <Row mb>
                    <Col xs>
                      <H2 cell>Horário da Unidade</H2>
                      <Row>
                        {horarios.map((item, index) => (
                          <Col key={item.name} xs={12} md={5} mdOffset={index % 2 ? 2 : 0}>
                            <H3>{`${item.name}:`}</H3>
                            <WorkingHourList workingHoursList={item.horarios} />
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
              {/* // Why `exams[0] !== ''`, this kind of data is being sent by the unit plugin (when there's no exam) */}
              {exams && exams.length > 0 && exams[0] !== '' && (
                <Row mb>
                  <Col xs={12}>
                    <Divisor />
                    <H2>{staticInfo.examesTitulo}</H2>
                    <Separator />
                    <MultipleCollumnWrapper numberOfCollumns={2}>
                      {exams.map(exam => (
                        <Link
                          key={exam}
                          to={`${getPathUrl(appPaths.search.path, isDoctor)}${getSearchQueryParam(
                            exam
                          )}`}
                        >
                          <Body>{exam}</Body>
                        </Link>
                      ))}
                    </MultipleCollumnWrapper>
                  </Col>
                </Row>
              )}
              {!downOfMd && unidade.outrasinformacoes && (
                <Row mb>
                  <Col xs={12}>
                    <Divisor />
                    <H2>{unidade.titulo_informacoes ?? "Outras informações"}</H2>
                    <Separator />
                    <div
                      className="otherinfo"
                      dangerouslySetInnerHTML={{
                        __html: unidade.outrasinformacoes
                      }}
                    />                    
                  </Col>
                </Row>
              )}
            </Col>

            <Col xs={12} mdOffset={1} md={4}>
              {facilities && facilities.length > 0 && (
                <>
                  <Row mb>
                    <Col xs={12} md={false}>
                      <Divisor />
                    </Col>
                    <Col xs>
                      <H2>{staticInfo.facilidadesTitulo}</H2>
                      <Separator />
                      {facilities && (
                        <Row mb>
                          {facilities.map(facility => (
                            <React.Fragment key={facility.facilidade}>
                              <Col xs={12}>
                                <FacilityItem
                                  icon={
                                    <LazyLoadImage
                                      width={parseInt(FontSize.Large, 10)}
                                      src={facility?.icone?.imgix_url}
                                      alt={`Icone de ${facility.facilidade}`}
                                    />
                                  }
                                  text={facility.facilidade}
                                />
                              </Col>
                            </React.Fragment>
                          ))}
                        </Row>
                      )}
                    </Col>
                  </Row>
                  <Divisor />
                </>
              )}
              {downOfMd && unidade.outrasinformacoes && (
                <>
                  <Row mb>
                    <Col xs={12}>
                      <Divisor />
                      <H2>{unidade.titulo_informacoes ?? "Outras informações"}</H2>
                      <Separator />
                      <div
                        className="otherinfo"
                        dangerouslySetInnerHTML={{
                          __html: unidade.outrasinformacoes
                        }}
                      />
                    </Col>
                  </Row>
                  <Divisor />
                </>
              )}

              {/* {unidade.servicosDiferenciados && unidade.servicosDiferenciados.length > 0 && (
                <>
                  <Row mb>
                    <Col xs>
                      <H2>{staticInfo.diferenciaisTitulo}</H2>
                      {unidade.servicosDiferenciados.map((differential, index) => (
                        <Row mb key={differential.title}>
                          <Col xs={8}>
                            <H3>{differential.title}</H3>
                            <Button
                              id={`button-unit-diferential-${index}`}
                              kind="link"
                              to={getDetailPageUrl(
                                getPathUrl(appPaths.differentiatedServices.path, isDoctor),
                                differential.slug
                              )}
                            >
                              Ver mais
                            </Button>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                  <Divisor />
                </>
              )} */}

              <Row mb>
                <Col xs>
                  <H2>{staticInfo.contatoTitulo}</H2>
                  <Body>
                    <div dangerouslySetInnerHTML={{ __html: staticInfo.contatoConteudoHTML }} />
                  </Body>
                </Col>
              </Row>

              <Row mb>
                <Col xs={12} md={8}>
                  <BooleanContainer>
                    {schedulingModalBool => (
                      <div>
                        <Button
                          id="button-scheduling-exam"
                          kind="primary"
                          onClick={schedulingModalBool.setTrue}
                          expanded
                        >
                          Agendar exame
                        </Button>
                        <SchedulingModal
                          open={schedulingModalBool.value}
                          onClose={schedulingModalBool.setFalse}
                        />
                      </div>
                    )}
                  </BooleanContainer>
                </Col>
              </Row>
            </Col>
          </Row>
          <LargeSeparatorRow />
        </Grid>

        <MapModal
          unitIds={allUnitIds}
          defaultSelectedUnitId={selectedUnitId}
          mapModalOpen={mapModalOpen}
          showFacilityFilter={false}
          onClose={hideModal}
          location={props.location}
        />
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

export default UnitDetailTemplate

interface Horario {
  titulo?: string
  observacao?: string
  horario_inicial?: string
  horario_final?: string
}

interface IWorkingHourListProps {
  workingHoursList: Horario[]
}

const WorkingHourList: React.FunctionComponent<IWorkingHourListProps> = props => {
  return (
    <>
      {props.workingHoursList.map(item => (
        <React.Fragment key={item.titulo}>
          <Body>{`${item.titulo}: ${item.horario_inicial} às ${item.horario_final}`}</Body>
          {item.observacao && <BodySecondary>{`Observação: ${item.observacao}`}</BodySecondary>}
          <Separator />
        </React.Fragment>
      ))}
    </>
  )
}

const getWorkingHourNameFromKey = (key: keyof UnidadesHorariosAtendimento) => {
  switch (key) {
    case 'segunda_a_sexta':
      return 'Seg. a sex.'
    case 'sabado':
      return 'Sábado'
    case 'domingo':
      return 'Domingo'
    case 'feriado':
      return 'Feriado'
    default:
      return ''
  }
}

// const getServicosDiferenciadosString = (servicos: string[]) => {
//   if (servicos.length > 1) {
//     return servicos
//       .slice(0, servicos.length - 1)
//       .join(', ')
//       .concat(` e ${servicos.pop()}`)
//   }
//   return servicos[0]
// }


export const query = graphql`
  query UnitDetailTemplate {
    site {
      siteMetadata {
        siteUrl
      }
    }

    allUnidades {
      edges {
        node {
          idLegado
          slug
        }
      }
    }

    cosmicjsInformacoesEstaticas(slug: { eq: "unidade-detalhe" }) {
      metadata {
        diferenciaisTitulo
        facilidadesTitulo
        contatoTitulo
        contatoConteudoHTML
        examesTitulo
      }
    }
  }
`

