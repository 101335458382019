import { GeocodeResult } from 'atomic/legacy/atm.map/utils'
import { useUserLocation } from 'atomic/obj.custom-hooks/user-location.hook'
import { useState } from 'react'

type useMapInteractionsType = () => [
  'user' | 'search',
  google.maps.LatLngLiteral,
  (eventCallback: any) => () => void,
  GeocodeResult,
  (eventCallback: any) => (result: GeocodeResult) => void,
  string[],
  (eventCallback: any) => (val: any) => void
]
export const useMapInteractions: useMapInteractionsType = () => {
  const userLocation = useUserLocation()

  const [selectedFilter, setSelectedFilter] = useState<string[]>()
  const [searchLocation, setSearchLocation] = useState<GeocodeResult>()
  const [origin, setOrigin] = useState<'user' | 'search'>('user')

  const handleFilterSelectionChangeHOF = eventCallback => val => {
    setSelectedFilter(val)
    if (eventCallback) eventCallback()
  }

  const handleSearchSuccessHOF = eventCallback => (result: GeocodeResult) => {
    setOrigin('search')
    setSearchLocation(result)
    if (eventCallback) eventCallback()
  }

  const handlePanToUserHOF = eventCallback => () => {
    setOrigin('user')
    if (eventCallback) eventCallback()
  }

  return [
    origin,
    userLocation,
    handlePanToUserHOF,
    searchLocation,
    handleSearchSuccessHOF,
    selectedFilter,
    handleFilterSelectionChangeHOF
  ]
}
