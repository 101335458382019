import { Facility } from './component/unit-cell.component'

export const getUniqueFacilities = (allFacilities: Facility[]): Facility[] => {
  const facilitiesbyKey = {}
  allFacilities.forEach(item => {
    if (item.facilityName) {
      facilitiesbyKey[item.facilityName] = item
    }
  })

  return Object.keys(facilitiesbyKey).map(key => facilitiesbyKey[key])
}
