import {
  AutoMargin,
  Body,
  BodySecondary,
  Button,
  ButtonW,
  Col,
  FontSize,
  H3,
  Hbox,
  Row,
  Sep,
  Separator
} from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import { SvgIcPlusIcon } from 'atomic/atm.svg-icon/ic-plus-icon.component'
import { Query } from '@root/src/data/graphql/graphql-types'
import { parseNumber } from 'utils/number'
import { graphql, Link, StaticQuery } from 'gatsby'
import * as React from 'react'
import FacilityItem from './facility-item.component'
import { FacilitiesContainer, UnitCellStyled, UnitCellStyledNew } from './unit-cell.component.style'
import FacilityItemNew from './facility-item.component-new'

export interface Facility {
  iconSrc: string
  facilityName: string
}

export interface UnitCellProps {
  unitName: string
  unitAddress: string
  link1OnClick?: () => void
  link1Text?: string
  link1To?: string
  link2To?: string
  link2Text?: string
  selected?: boolean
  distanceInKm?: number
  facilities?: Facility[]
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  slug: string
}

// BUSINESS-RULE: "34: Lista  - Se distância maior que 500 k, não apresentar / se distância maior que 1km, arredondar o número (sem decimal) / colocar pontuação entre os milhares"
const maximumUnitDistanceInKmToShow = 500

const UnitCell: React.FunctionComponent<UnitCellProps> = ({ slug, ...props }) => {
  const [hover, setHover] = React.useState(false)
  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setHover(true)
    if (props.onMouseEnter) {
      props.onMouseEnter(event)
    }
  }
  const handleMouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setHover(false)
    if (props.onMouseLeave) {
      props.onMouseLeave(event)
    }
  }

  const content = React.useMemo(() => {
    return (
      <>
        <Link id={`anchor-unit-cell-${slug}`} to={props.link2To}>
          <Row mb>
            <Col xs>
              <Hbox>
                <Hbox.Item wrap vAlign="center">
                  <StaticQuery
                    query={graphql`
                      {
                        cosmicjsInformacoesEstaticas(slug: { eq: "unidades" }) {
                          metadata {
                            unitPin {
                              imgix_url
                            }
                            selectedUnitPin {
                              imgix_url
                            }
                            unitPinHover {
                              imgix_url
                            }
                          }
                        }
                      }
                    `}
                    render={(data: Query) => {
                      const metadata = data.cosmicjsInformacoesEstaticas.metadata
                      let src = metadata.unitPin.imgix_url
                      if (props.selected) {
                        src = metadata.selectedUnitPin.imgix_url
                      }
                      if (hover) {
                        src = metadata.unitPinHover.imgix_url
                      }
                      return <img src={src} alt={'pin'} />
                    }}
                  />
                </Hbox.Item>
                <Hbox.Separator />
                <Hbox.Item vAlign="center">
                  <H3 cell>{props.unitName}</H3>
                </Hbox.Item>
                {props.distanceInKm && props.distanceInKm < maximumUnitDistanceInKmToShow && (
                  <Hbox.Item wrap vAlign="center">
                    <BodySecondary>
                      {props.distanceInKm > 10
                        ? `${parseNumber(props.distanceInKm)} km`
                        : props.distanceInKm > 1
                        ? `${parseNumber(props.distanceInKm, 1)} km`
                        : `${parseNumber(props.distanceInKm * 1000)} m`}
                    </BodySecondary>
                  </Hbox.Item>
                )}
              </Hbox>
            </Col>
          </Row>
          <Row mb>
            <Col xs>
              <Body>{props.unitAddress}</Body>
            </Col>
          </Row>
        </Link>
        {props.facilities &&
          (process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' ? (
            <FacilitiesBody facilities={props.facilities} />
          ) : (
            <Row>
              <Col>
                <FacilitiesBody facilities={props.facilities} />
              </Col>
            </Row>
          ))}
        <Separator />
        <AutoMargin />
        <Row between={'xs'} bottom={'xs'}>
          {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' && (
            <>
              <Sep />
              <Col>
                <ButtonW
                  id={`button-see-on-map-${slug}`}
                  kind="link"
                  to={props.link1To}
                  onClick={props.link1OnClick}
                >
                  {props.link1Text}
                </ButtonW>
              </Col>
              {props.link2Text && (
                <Col>
                  <ButtonW id={`button-see-on-map-2-${slug}`} kind="link" to={props.link2To}>
                    {props.link2Text}
                  </ButtonW>
                </Col>
              )}
            </>
          )}
          {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() !== 'weinmann' && (
            <>
              <Col>
                <Button
                  id={`button-see-on-map-${slug}`}
                  kind="link"
                  to={props.link1To}
                  onClick={props.link1OnClick}
                >
                  {props.link1Text}
                </Button>
              </Col>
              {props.link2Text && (
                <Col>
                  <Button id={`button-see-on-map-2-${slug}`} kind="link" to={props.link2To}>
                    {props.link2Text}
                  </Button>
                </Col>
              )}
            </>
          )}
        </Row>
      </>
    )
  }, [
    hover,
    props.unitName,
    props.unitAddress,
    props.link1Text,
    props.link2To,
    props.link2Text,
    props.selected,
    props.distanceInKm,
    props.facilities && props.facilities.length
  ])

  return process.env.GATSBY_COSMIC_BUCKET.toLowerCase() !== 'weinmann' ? (
    <UnitCellStyled
      id={`unit-cell-${slug}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={props.onClick}
    >
      {content}
    </UnitCellStyled>
  ) : (
    <UnitCellStyledNew
      id={`unit-cell-${slug}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={props.onClick}
    >
      {content}
    </UnitCellStyledNew>
  )
}

interface FacilitiesBodyProps {
  facilities: Facility[]
}

const FacilitiesBody: React.FunctionComponent<FacilitiesBodyProps> = React.memo(props => {
  const showSize = props.facilities.length === 3 ? 3 : 2
  const facilitiesShown = props.facilities.slice(0, showSize)
  const remainingFacilities = props.facilities.slice(showSize)

  return (
    <>
      {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() !== 'weinmann' ? (
        facilitiesShown.map(
          facility =>
            facility.facilityName &&
            facility.iconSrc && (
              <FacilityItem
                icon={
                  <LazyLoadImage
                    width={parseInt(FontSize.Large, 10)}
                    src={facility.iconSrc}
                    alt={`Icone de ${facility.facilityName}`}
                  />
                }
                text={facility.facilityName}
                key={facility.facilityName}
              />
            )
        )
      ) : (
        <FacilitiesContainer>
          {facilitiesShown.map(
            facility =>
              facility.facilityName &&
              facility.iconSrc && (
                <FacilityItemNew text={facility.facilityName} key={facility.facilityName} />
              )
          )}
        </FacilitiesContainer>
      )}

      {remainingFacilities.length > 0 && (
        <FacilityItem
          icon={<SvgIcPlusIcon width={parseInt(FontSize.Large, 10)} />}
          text={`${remainingFacilities.length} facilidades`}
        />
      )}
    </>
  )
})

export default UnitCell
