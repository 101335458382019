import { GeocodeBounds } from './google-maps.service'

export const getGoogleMapURL = googleMapsKey => `https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&v=3.exp`

/**
 * # Calculate distance between two latitude-longitude points? (Haversine formula)
 * https://stackoverflow.com/a/27943/3670829
 */
export function getDistanceFromLatLonInKm(pos1: google.maps.LatLngLiteral, pos2: google.maps.LatLngLiteral) {
  const lat1 = pos1.lat
  const lon1 = pos1.lng
  const lat2 = pos2.lat
  const lon2 = pos2.lng

  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1) // deg2rad below
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return d
}

function deg2rad(deg) {
  return deg * (Math.PI / 180)
}

/**
 * # How to calculate the zoom level for a given bounds
 * https://stackoverflow.com/a/13274361/3670829
 */
export function getBoundsZoomLevel(bounds: google.maps.LatLngBounds, mapDim: { height: number; width: number }) {
  const WORLD_DIM = { height: 256, width: 256 }
  const ZOOM_MAX = 21

  const ne = bounds.getNorthEast()
  const sw = bounds.getSouthWest()

  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI

  const lngDiff = ne.lng() - sw.lng()
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction)
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction)

  return Math.min(latZoom, lngZoom, ZOOM_MAX)
}

/**
 *
 * # How to calculate the latlngbounds from location
 * https://stackoverflow.com/questions/41555263/compute-latlngbounds-from-user-location
 * @param location
 * @param boundRadius in meters
 */
export function getBoundsFromLocation(location: google.maps.LatLngLiteral, boundRadius: number): GeocodeBounds {
  const circleArea = new google.maps.Circle({ radius: boundRadius, center: location })
  const bounds = circleArea.getBounds()
  const ne = bounds.getNorthEast().toJSON()
  const sw = bounds.getSouthWest().toJSON()
  return {
    south: sw.lat,
    west: sw.lng,
    north: ne.lat,
    east: ne.lng
  }
}

function latRad(lat) {
  const sin = Math.sin((lat * Math.PI) / 180)
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
}

function zoom(mapPx, worldPx, fraction) {
  return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
}

/**
 * how to find out the area in google maps api
 * https://stackoverflow.com/a/34453166/3670829
 */
export function getBoundsAreaInKm(bounds: google.maps.LatLngBounds) {
  const ne = bounds.getNorthEast().toJSON()
  const sw = bounds.getSouthWest().toJSON()

  const nw = { lat: ne.lat, lng: sw.lng }
  const se = { lat: sw.lat, lng: ne.lng }

  const length = getDistanceFromLatLonInKm(sw, nw)
  const breadth = getDistanceFromLatLonInKm(sw, se)
  return length * breadth
}
