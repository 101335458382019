import { BodySecondary, FontSize, Hbox } from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'
import * as React from 'react'

interface IconTextCellProps {
  iconSrc: string
  text: string
  light?: boolean
}

export const IconTextCell: React.FunctionComponent<IconTextCellProps> = props => (
  <Hbox vAlign="center">
    <Hbox.Item vAlign="center" wrap>
      <LazyLoadImage
        width={parseInt(FontSize.Large, 10)}
        src={props.iconSrc}
        alt={`Icone de ${props.iconSrc}`}
      />
    </Hbox.Item>
    <Hbox.Separator />
    <Hbox.Item vAlign="center">
      <BodySecondary kind={props.light ? 'light' : 'default'}>{props.text}</BodySecondary>
    </Hbox.Item>
  </Hbox>
)
